import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import { useAuth } from '../../../auth/AuthProvider';

const Sidebar = ({ toggleSidebar, opened }) => {
    const navigate = useNavigate();
    const { user, logout } = useAuth();

    const handleLogout = async () => {
        await logout();
        navigate('/');
    };

    const getMenuItems = () => {
        if (user?.userLevel === 9) {
            return [
                { title: 'Dashboard Home', path: 'home' },
                { title: 'Request a Quote', path: 'quote' },
                { title: 'Order Vehicle', path: 'order' },
                { title: 'View Quotes', path: 'quotes' },
                { title: 'View Orders', path: 'orders' },
                { title: 'Accident Reports', path: 'accident-reports' },
                { title: 'Reporting', path: 'reporting' },
                { title: 'Business Profile', path: 'business-profile' },
                { title: 'Business Admin', path: 'business-profiles' },
                { title: 'Manage Users', path: 'manage-users' },
                { title: 'Settings', path: 'settings' },
                { title: 'FAQs', path: 'faqs' },
                { title: 'My Profile', path: 'profile' }
            ];
        }

        const baseItems = [
            { title: 'Dashboard Home', path: 'home' },
            { title: 'Request a Quote', path: 'quote' },
            { title: 'Order Vehicle', path: 'order' },
            { title: 'View Quotes', path: 'quotes' },
            { title: 'View Orders', path: 'orders' },
            { title: 'Accident Support', path: 'accident-support' },
            { title: 'Accident Reports', path: 'accident-reports' },
            { title: 'FAQs', path: 'faqs' },
            { title: 'Business Profile', path: 'business-profile' },
            { title: 'My Profile', path: 'profile' }
        ];

        if (user?.userLevel === 2) {
            baseItems.push({ title: 'Manage Users', path: 'manage-users' });
        }

        return baseItems;
    };

    return (
        <>
            <div className={`sidebar-container ${opened ? 'active' : ''}`}>
                <div className="sidebar">
                    <ul className="sidebar-links">
                        {getMenuItems().map((item, i) => (
                            <li key={i}>
                                <NavLink
                                    to={item.path}
                                    className={({ isActive }) =>
                                        `sidebar-button ${isActive ? 'active' : ''} ${user?.userLevel === 9 ? 'admin-button' : ''
                                        }`
                                    }
                                >
                                    {item.title}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                    <button onClick={handleLogout} className="logout-button">
                        Logout
                    </button>
                </div>
            </div>
            <div
                className={`sidebar-overlay ${opened ? 'active' : ''}`}
                onClick={toggleSidebar}
            ></div>
        </>
    );
};

export default Sidebar;