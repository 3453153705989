import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import { ApiRepos } from '../../../contracts/ContractVars';
import { useAuth } from '../../../auth/AuthProvider';
import './BusinessProfiles.css';

const BusinessProfiles = ({ userData }) => {
    const [businesses, setBusinesses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { isAdmin } = useAuth();  // Use the auth hook to check admin status
    const [successMessage, setSuccessMessage] = useState('');
    const location = useLocation();

    useEffect(() => {
        const fetchBusinesses = async () => {
            try {
                const response = await fetch(`${ApiRepos}business/list`, {
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('You do not have permission to view businesses');
                }

                const data = await response.json();
                setBusinesses(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBusinesses();
    }, []);

    useEffect(() => {
        if (location.state?.successMessage) {
            setSuccessMessage(location.state.successMessage);
            // Clear the message after 5 seconds
            const timer = setTimeout(() => setSuccessMessage(''), 5000);
            return () => clearTimeout(timer);
        }
    }, [location]);

    const handleHoldToggle = async (businessId, currentHoldStatus) => {
        try {
            const response = await fetch(`${ApiRepos}business/${businessId}/hold`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({ onHold: !currentHoldStatus })
            });

            if (!response.ok) {
                throw new Error('Failed to update business status');
            }

            setBusinesses(businesses.map(business => {
                if (business.id === businessId) {
                    return { ...business, onHold: !business.onHold };
                }
                return business;
            }));
        } catch (err) {
            setError(err.message);
        }
    };

    if (!isAdmin) {
        return <Navigate to="/dashboard" replace />;
    }

    if (loading) return <div className="businessprofiles-loading">Loading...</div>;
    if (error) return <div className="businessprofiles-error">Error: {error}</div>;

    return (
        <div className="businessprofiles-container">
            {successMessage && (
                <div className="success-message mb-4 p-4 bg-green-100 border border-green-400 text-green-700 rounded">
                    {successMessage}
                </div>
            )}
            <div className="businessprofiles-header">
                <h1>BUSINESS ADMIN</h1>
                <button
                    onClick={() => navigate('/dashboard/business/new')}
                    className="action-button add-new"
                >
                    ADD NEW BUSINESS
                </button>
            </div>

            <div className="businessprofiles-table-container">
                <table className="businessprofiles-table">
                    <thead>
                        <tr>
                            <th>Business Name</th>
                            <th>VAT Number</th>
                            <th>Website</th>
                            <th>Status</th>
                            <th>Registration Code</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {businesses.map(business => (
                            <tr key={business.id}>
                                <td>{business.businessName}</td>
                                <td>{business.vatNumber || 'N/A'}</td>
                                <td>
                                    {business.website ? (
                                        <a
                                            href={business.website}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="website-link"
                                        >
                                            {business.website}
                                        </a>
                                    ) : 'N/A'}
                                </td>
                                <td>{business.onHold ? 'On Hold' : 'Active'}</td>
                                <td>{business.registrationCode}</td>
                                <td>
                                    <div className="action-buttons">
                                        <button
                                            onClick={() => handleHoldToggle(business.id, business.onHold)}
                                            className="action-button hold-button"
                                        >
                                            HOLD
                                        </button>
                                        <button
                                            onClick={() => navigate(`/dashboard/business/${business.id}`)}
                                            className="action-button edit-button"
                                        >
                                            Edit
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BusinessProfiles;