import React, { useState, useEffect, useCallback } from 'react';
import { ApiRepos } from '../../../contracts/ContractVars';

const Passengers = ({ reportId, isDisabled }) => {
    const [passengers, setPassengers] = useState([{
        id: null,
        name: '',
        address: ''
    }]);

    const fetchPassengers = useCallback(async () => {
        try {
            const response = await fetch(`${ApiRepos}Passenger/${reportId}`, {
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                if (data.length > 0) {
                    setPassengers(data);
                }
            }
        } catch (err) {
            console.error('Error fetching passengers:', err);
        }
    }, [reportId]);

    useEffect(() => {
        if (reportId) {
            fetchPassengers();
        }
    }, [fetchPassengers, reportId]);

    const handleChange = async (e, index) => {
        const { name, value } = e.target;
        const updatedPassengers = [...passengers];
        updatedPassengers[index] = {
            ...updatedPassengers[index],
            [name]: value
        };

        setPassengers(updatedPassengers);

        if (updatedPassengers[index].id) {
            try {
                await fetch(`${ApiRepos}Passenger/${updatedPassengers[index].id}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',
                    body: JSON.stringify(updatedPassengers[index])
                });
            } catch (err) {
                console.error('Error updating passenger:', err);
            }
        }
    };

    const addPassenger = async () => {
        try {
            const response = await fetch(`${ApiRepos}Passenger/${reportId}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({
                    name: '',
                    address: ''
                })
            });

            if (response.ok) {
                const newPassenger = await response.json();
                setPassengers([...passengers, newPassenger]);
            }
        } catch (err) {
            console.error('Error adding passenger:', err);
        }
    };

    const removePassenger = async (id, index) => {
        if (passengers.length > 1) {
            try {
                if (id) {
                    await fetch(`${ApiRepos}Passenger/${id}`, {
                        method: 'DELETE',
                        credentials: 'include'
                    });
                }
                const updatedPassengers = passengers.filter((_, i) => i !== index);
                setPassengers(updatedPassengers);
            } catch (err) {
                console.error('Error removing passenger:', err);
            }
        }
    };

    return (
        <div className="accident-form-section">
            <h3>Passengers</h3>
            {passengers.map((passenger, index) => (
                <div key={passenger.id || index} className="accident-array-item">
                    <div className="accident-array-item-header">
                        <span className="accident-array-item-title">Passenger {index + 1}</span>
                        {passengers.length > 1 && !isDisabled && (
                            <button
                                type="button"
                                onClick={() => removePassenger(passenger.id, index)}
                                className="accident-remove-button"
                            >
                                Remove
                            </button>
                        )}
                    </div>

                    <div className="accident-form-row">
                        <div className="accident-form-group">
                            <label>Name</label>
                            <input
                                type="text"
                                name="name"
                                value={passenger.name}
                                onChange={(e) => handleChange(e, index)}
                                disabled={isDisabled}
                                className="accident-input"
                            />
                        </div>
                    </div>

                    <div className="accident-form-row">
                        <div className="accident-form-group">
                            <label>Address</label>
                            <textarea
                                name="address"
                                value={passenger.address}
                                onChange={(e) => handleChange(e, index)}
                                disabled={isDisabled}
                                rows={3}
                                className="accident-textarea"
                            />
                        </div>
                    </div>
                </div>
            ))}

            {!isDisabled && (
                <button
                    type="button"
                    onClick={addPassenger}
                    className="accident-add-button"
                >
                    Add Passenger
                </button>
            )}
        </div>
    );
};

export default Passengers;