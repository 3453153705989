import React, { useState, useEffect, useCallback } from 'react';
import { ApiRepos } from '../../../contracts/ContractVars';

const Witnesses = ({ reportId, isDisabled }) => {
    const [witnesses, setWitnesses] = useState([{
        id: null,
        name: '',
        address: ''
    }]);

    const fetchWitnesses = useCallback(async () => {
        try {
            const response = await fetch(`${ApiRepos}Witness/${reportId}`, {
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                if (data.length > 0) {
                    setWitnesses(data);
                }
            }
        } catch (err) {
            console.error('Error fetching witnesses:', err);
        }
    }, [reportId]);

    useEffect(() => {
        if (reportId) {
            fetchWitnesses();
        }
    }, [fetchWitnesses, reportId]);

    const handleChange = async (e, index) => {
        const { name, value } = e.target;
        const updatedWitnesses = [...witnesses];
        updatedWitnesses[index] = {
            ...updatedWitnesses[index],
            [name]: value
        };

        setWitnesses(updatedWitnesses);

        if (updatedWitnesses[index].id) {
            try {
                await fetch(`${ApiRepos}Witness/${updatedWitnesses[index].id}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',
                    body: JSON.stringify(updatedWitnesses[index])
                });
            } catch (err) {
                console.error('Error updating witness:', err);
            }
        }
    };

    const addWitness = async () => {
        try {
            const response = await fetch(`${ApiRepos}Witness/${reportId}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({
                    name: '',
                    address: ''
                })
            });

            if (response.ok) {
                const newWitness = await response.json();
                setWitnesses([...witnesses, newWitness]);
            }
        } catch (err) {
            console.error('Error adding witness:', err);
        }
    };

    const removeWitness = async (id, index) => {
        if (witnesses.length > 1) {
            try {
                if (id) {
                    await fetch(`${ApiRepos}Witness/${id}`, {
                        method: 'DELETE',
                        credentials: 'include'
                    });
                }
                const updatedWitnesses = witnesses.filter((_, i) => i !== index);
                setWitnesses(updatedWitnesses);
            } catch (err) {
                console.error('Error removing witness:', err);
            }
        }
    };

    return (
        <div className="accident-form-section">
            <h3>Witnesses</h3>
            {witnesses.map((witness, index) => (
                <div key={witness.id || index} className="accident-array-item">
                    <div className="accident-array-item-header">
                        <span className="accident-array-item-title">Witness {index + 1}</span>
                        {witnesses.length > 1 && !isDisabled && (
                            <button
                                type="button"
                                onClick={() => removeWitness(witness.id, index)}
                                className="accident-remove-button"
                            >
                                Remove
                            </button>
                        )}
                    </div>

                    <div className="accident-form-row">
                        <div className="accident-form-group">
                            <label>Name</label>
                            <input
                                type="text"
                                name="name"
                                value={witness.name}
                                onChange={(e) => handleChange(e, index)}
                                disabled={isDisabled}
                                className="accident-input"
                            />
                        </div>
                    </div>

                    <div className="accident-form-row">
                        <div className="accident-form-group">
                            <label>Address</label>
                            <textarea
                                name="address"
                                value={witness.address}
                                onChange={(e) => handleChange(e, index)}
                                disabled={isDisabled}
                                rows={3}
                                className="accident-textarea"
                            />
                        </div>
                    </div>
                </div>
            ))}

            {!isDisabled && (
                <button
                    type="button"
                    onClick={addWitness}
                    className="accident-add-button"
                >
                    Add Witness
                </button>
            )}
        </div>
    );
};

export default Witnesses;