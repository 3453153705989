import React, { useState, useEffect, useCallback } from 'react';
import { ApiRepos } from '../../../contracts/ContractVars';

const InjuredPersons = ({ reportId, isDisabled }) => {
    const [injuredPersons, setInjuredPersons] = useState([{
        id: null,
        name: '',
        address: '',
        injuryDetails: '',
        ambulanceAttended: false,
        ambulanceDetails: ''
    }]);
    const [hasInjuredParties, setHasInjuredParties] = useState(false);

    const fetchInjuredPersons = useCallback(async () => {
        try {
            const response = await fetch(`${ApiRepos}InjuredPerson/${reportId}`, {
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                if (data.length > 0) {
                    setInjuredPersons(data);
                    setHasInjuredParties(true);
                }
            }
        } catch (err) {
            console.error('Error fetching injured persons:', err);
        }
    }, [reportId]);

    useEffect(() => {
        if (reportId) {
            fetchInjuredPersons();
        }
    }, [fetchInjuredPersons, reportId]);

    const handleChange = async (e, index) => {
        const { name, value, type, checked } = e.target;
        const updatedPersons = [...injuredPersons];
        updatedPersons[index] = {
            ...updatedPersons[index],
            [name]: type === 'checkbox' ? checked : value
        };

        setInjuredPersons(updatedPersons);

        if (updatedPersons[index].id) {
            try {
                await fetch(`${ApiRepos}InjuredPerson/${updatedPersons[index].id}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',
                    body: JSON.stringify(updatedPersons[index])
                });
            } catch (err) {
                console.error('Error updating injured person:', err);
            }
        }
    };

    const addInjuredPerson = async () => {
        try {
            const response = await fetch(`${ApiRepos}InjuredPerson/${reportId}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({
                    name: '',
                    address: '',
                    injuryDetails: '',
                    ambulanceAttended: false,
                    ambulanceDetails: ''
                })
            });

            if (response.ok) {
                const newPerson = await response.json();
                setInjuredPersons([...injuredPersons, newPerson]);
            }
        } catch (err) {
            console.error('Error adding injured person:', err);
        }
    };

    const removeInjuredPerson = async (id, index) => {
        if (injuredPersons.length > 1) {
            try {
                if (id) {
                    await fetch(`${ApiRepos}InjuredPerson/${id}`, {
                        method: 'DELETE',
                        credentials: 'include'
                    });
                }
                const updatedPersons = injuredPersons.filter((_, i) => i !== index);
                setInjuredPersons(updatedPersons);
            } catch (err) {
                console.error('Error removing injured person:', err);
            }
        }
    };

    return (
        <div className="accident-form-section">
            <h3>Injured Persons</h3>
            <div className="accident-form-row">
                <div className="accident-form-group">
                    <label className="accident-checkbox-label">
                        <input
                            type="checkbox"
                            checked={hasInjuredParties}
                            onChange={(e) => setHasInjuredParties(e.target.checked)}
                            disabled={isDisabled}
                            className="accident-checkbox-input"
                        />
                        <span>Were there any injuries?</span>
                    </label>
                </div>
            </div>

            {hasInjuredParties && injuredPersons.map((person, index) => (
                <div key={person.id || index} className="accident-array-item">
                    <div className="accident-array-item-header">
                        <span className="accident-array-item-title">Injured Person {index + 1}</span>
                        {injuredPersons.length > 1 && !isDisabled && (
                            <button
                                type="button"
                                onClick={() => removeInjuredPerson(person.id, index)}
                                className="accident-remove-button"
                            >
                                Remove
                            </button>
                        )}
                    </div>

                    <div className="accident-form-row">
                        <div className="accident-form-group">
                            <label>Name</label>
                            <input
                                type="text"
                                name="name"
                                value={person.name}
                                onChange={(e) => handleChange(e, index)}
                                disabled={isDisabled}
                                className="accident-input"
                            />
                        </div>
                    </div>

                    <div className="accident-form-row">
                        <div className="accident-form-group">
                            <label>Address</label>
                            <textarea
                                name="address"
                                value={person.address}
                                onChange={(e) => handleChange(e, index)}
                                disabled={isDisabled}
                                rows={3}
                                className="accident-textarea"
                            />
                        </div>
                    </div>

                    <div className="accident-form-row">
                        <div className="accident-form-group">
                            <label>Injury Details</label>
                            <textarea
                                name="injuryDetails"
                                value={person.injuryDetails}
                                onChange={(e) => handleChange(e, index)}
                                disabled={isDisabled}
                                rows={3}
                                className="accident-textarea"
                            />
                        </div>
                    </div>

                    <div className="accident-form-row">
                        <div className="accident-form-group">
                            <label className="accident-checkbox-label">
                                <input
                                    type="checkbox"
                                    name="ambulanceAttended"
                                    checked={person.ambulanceAttended}
                                    onChange={(e) => handleChange(e, index)}
                                    disabled={isDisabled}
                                    className="accident-checkbox-input"
                                />
                                <span>Did an ambulance attend?</span>
                            </label>
                        </div>
                    </div>

                    {person.ambulanceAttended && (
                        <div className="accident-form-row">
                            <div className="accident-form-group">
                                <label>Ambulance Details</label>
                                <textarea
                                    name="ambulanceDetails"
                                    value={person.ambulanceDetails}
                                    onChange={(e) => handleChange(e, index)}
                                    disabled={isDisabled}
                                    rows={2}
                                    className="accident-textarea"
                                    placeholder="Please provide any relevant ambulance/paramedic details"
                                />
                            </div>
                        </div>
                    )}
                </div>
            ))}

            {hasInjuredParties && !isDisabled && (
                <button
                    type="button"
                    onClick={addInjuredPerson}
                    className="accident-add-button"
                >
                    Add Another Injured Person
                </button>
            )}
        </div>
    );
};

export default InjuredPersons;