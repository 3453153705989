import React, { useState, useEffect, useCallback } from 'react';
import { ApiRepos } from '../../../contracts/ContractVars';

const ThirdParties = ({ reportId, isDisabled }) => {
    const [thirdParties, setThirdParties] = useState([{
        id: null,
        name: '',
        address: '',
        telephoneNumber: '',
        vehicleMakeModel: '',
        vehicleRegistration: '',
        insurersDetails: '',
        speed: '',
        vehicleDamage: '',
        numberOfOccupants: null
    }]);
    const [hasThirdParties, setHasThirdParties] = useState(false);

    const fetchThirdParties = useCallback(async () => {
        try {
            const response = await fetch(`${ApiRepos}ThirdParty/${reportId}`, {
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                if (data.length > 0) {
                    setThirdParties(data);
                    setHasThirdParties(true);
                }
            }
        } catch (err) {
            console.error('Error fetching third parties:', err);
        }
    }, [reportId]);

    useEffect(() => {
        if (reportId) {
            fetchThirdParties();
        }
    }, [fetchThirdParties, reportId]);   

    const handleChange = async (e, index) => {
        const { name, value } = e.target;
        const updatedParties = [...thirdParties];
        updatedParties[index] = {
            ...updatedParties[index],
            [name]: value
        };

        setThirdParties(updatedParties);

        // If party has ID, update it
        if (updatedParties[index].id) {
            try {
                await fetch(`${ApiRepos}ThirdParty/${updatedParties[index].id}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',
                    body: JSON.stringify(updatedParties[index])
                });
            } catch (err) {
                console.error('Error updating third party:', err);
            }
        }
    };

    const addThirdParty = async () => {
        try {
            const response = await fetch(`${ApiRepos}ThirdParty/${reportId}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({
                    name: '',
                    address: '',
                    telephoneNumber: '',
                    vehicleMakeModel: '',
                    vehicleRegistration: '',
                    insurersDetails: '',
                    speed: '',
                    vehicleDamage: '',
                    numberOfOccupants: null
                })
            });

            if (response.ok) {
                const newParty = await response.json();
                setThirdParties([...thirdParties, newParty]);
            }
        } catch (err) {
            console.error('Error adding third party:', err);
        }
    };

    const removeThirdParty = async (id, index) => {
        if (thirdParties.length > 1) {
            try {
                if (id) {
                    await fetch(`${ApiRepos}ThirdParty/${id}`, {
                        method: 'DELETE',
                        credentials: 'include'
                    });
                }
                const updatedParties = thirdParties.filter((_, i) => i !== index);
                setThirdParties(updatedParties);
            } catch (err) {
                console.error('Error removing third party:', err);
            }
        }
    };

    return (
        <div className="accident-form-section">
            <h3>Third Parties</h3>
            <div className="accident-form-row">
                <div className="accident-form-group">
                    <label className="accident-checkbox-label">
                        <input
                            type="checkbox"
                            checked={hasThirdParties}
                            onChange={(e) => setHasThirdParties(e.target.checked)}
                            disabled={isDisabled}
                            className="accident-checkbox-input"
                        />
                        <span>Were there any third parties involved?</span>
                    </label>
                </div>
            </div>

            {hasThirdParties && thirdParties.map((party, index) => (
                <div key={party.id || index} className="accident-array-item">
                    <div className="accident-array-item-header">
                        <span className="accident-array-item-title">Third Party {index + 1}</span>
                        {thirdParties.length > 1 && !isDisabled && (
                            <button
                                type="button"
                                onClick={() => removeThirdParty(party.id, index)}
                                className="accident-remove-button"
                            >
                                Remove
                            </button>
                        )}
                    </div>

                    <div className="accident-form-row">
                        <div className="accident-form-group">
                            <label>Name</label>
                            <input
                                type="text"
                                name="name"
                                value={party.name}
                                onChange={(e) => handleChange(e, index)}
                                disabled={isDisabled}
                                className="accident-input"
                            />
                        </div>
                        <div className="accident-form-group">
                            <label>Phone Number</label>
                            <input
                                type="tel"
                                name="telephoneNumber"
                                value={party.telephoneNumber}
                                onChange={(e) => handleChange(e, index)}
                                disabled={isDisabled}
                                className="accident-input"
                            />
                        </div>
                    </div>

                    <div className="accident-form-row">
                        <div className="accident-form-group">
                            <label>Address</label>
                            <textarea
                                name="address"
                                value={party.address}
                                onChange={(e) => handleChange(e, index)}
                                disabled={isDisabled}
                                rows={3}
                                className="accident-textarea"
                            />
                        </div>
                    </div>

                    <div className="accident-form-row">
                        <div className="accident-form-group">
                            <label>Vehicle Make & Model</label>
                            <input
                                type="text"
                                name="vehicleMakeModel"
                                value={party.vehicleMakeModel}
                                onChange={(e) => handleChange(e, index)}
                                disabled={isDisabled}
                                className="accident-input"
                            />
                        </div>
                        <div className="accident-form-group">
                            <label>Vehicle Registration</label>
                            <input
                                type="text"
                                name="vehicleRegistration"
                                value={party.vehicleRegistration}
                                onChange={(e) => handleChange(e, index)}
                                disabled={isDisabled}
                                className="accident-input"
                            />
                        </div>
                    </div>

                    <div className="accident-form-row">
                        <div className="accident-form-group">
                            <label>Insurance Details</label>
                            <input
                                type="text"
                                name="insurersDetails"
                                value={party.insurersDetails}
                                onChange={(e) => handleChange(e, index)}
                                disabled={isDisabled}
                                className="accident-input"
                            />
                        </div>
                        <div className="accident-form-group">
                            <label>Estimated Speed</label>
                            <input
                                type="text"
                                name="speed"
                                value={party.speed}
                                onChange={(e) => handleChange(e, index)}
                                disabled={isDisabled}
                                placeholder="e.g. 30 mph"
                                className="accident-input"
                            />
                        </div>
                    </div>

                    <div className="accident-form-row">
                        <div className="accident-form-group">
                            <label>Vehicle Damage</label>
                            <textarea
                                name="vehicleDamage"
                                value={party.vehicleDamage}
                                onChange={(e) => handleChange(e, index)}
                                disabled={isDisabled}
                                rows={3}
                                className="accident-textarea"
                            />
                        </div>
                    </div>

                    <div className="accident-form-row">
                        <div className="accident-form-group">
                            <label>Number of Occupants</label>
                            <input
                                type="number"
                                name="numberOfOccupants"
                                value={party.numberOfOccupants || ''}
                                onChange={(e) => handleChange(e, index)}
                                disabled={isDisabled}
                                min="1"
                                className="accident-input"
                            />
                        </div>
                    </div>
                </div>
            ))}

            {hasThirdParties && !isDisabled && (
                <button
                    type="button"
                    onClick={addThirdParty}
                    className="accident-add-button"
                >
                    Add Another Third Party
                </button>
            )}
        </div>
    );
};

export default ThirdParties;